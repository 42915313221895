import { Button, Dialog } from '@krakentech/coral';
import { FormikTextField } from '@krakentech/coral-formik';
import { IconSuccess } from '@krakentech/icons';
import * as Sentry from '@sentry/nextjs';
import { TFunction, useTranslation } from 'next-i18next';
import { ReactElement, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { validationRegex } from '@/components/helpers/validationRegex';
import { Spinner } from '@/components/svgs/Spinner';
import { copy } from '@/copy';
import { useSessionStorage } from '@/hooks/useSessionStorage';
import apiClient from '@/services/api-client';
import {
	CanSupplyRejectionType,
	FetchElectricitySupplyPointDetailsMutation,
	QuotedElectricitySupplyPoint,
} from '@/services/typed-graphql-sdk';
import { CompositionEvent } from '@/types/inputs';
import { SPIN_CHARACTER_LENGTH } from '@/utils/constants/constants';
import { SUPPORTED_GRID_OPERATOR_CODES } from '@/utils/constants/industry/gridOperator';
import { ContractCapacityOption } from '@/utils/constants/industry/industry';
import { fullWidthToHalfWidth } from '@/utils/formatters/fullWidthToHalfWidth';
import { numberHyphenator9000 } from '@/utils/formatters/numberHyphenator9000';
import { sendValidateSPINAnalytics } from '@/utils/googleAnalytics';
import { gridOperatorCodeFromSPIN } from '@/utils/industry';
import { noop } from '@/utils/noop';

export const getSPINFieldValidation = (
	t: TFunction
): Yup.StringSchema<string | undefined, object> =>
	Yup.string()
		.matches(validationRegex.isValidSPIN, t('errors.invalid-supply-point-id'))
		.when('COSGainType', {
			is: 'switchIn',
			then: (schema: Yup.StringSchema<string, object>) =>
				schema.required(t('errors.required')),
		});

/**
 * FetchElectricitySupplyPointDetailsMutation error types and messages
 *
 * @see https://github.com/octoenergy/kraken-core/blob/master/src/octoenergy/plugins/territories/jpn/interfaces/apisite/graphql/quoting/mutations.py
 */
const FETCH_SPIN_ERRORS: Record<string, RegExp> = {
	SPINAlreadyRegistered: /SPIN already registered/i,
	NoSPINFoundOCCTO: /NoSPINFoundOCCTO - SPIN does not exist on OCCTO/i,
	OCCTORequestFailed: /OCCTO could not process the request/i,
	Exception: /An unexpected error occurred when trying to fetch SPIN details/i,
};

const FETCH_SPIN_SERVICE_ERRORS: RegExp[] = [
	FETCH_SPIN_ERRORS.OCCTORequestFailed,
	FETCH_SPIN_ERRORS.Exception,
];

const isServiceError = (error: unknown): boolean => {
	const errorString = JSON.stringify(error, Object.getOwnPropertyNames(error));
	return FETCH_SPIN_SERVICE_ERRORS.some((regexp) => regexp.test(errorString));
};

export function SPINField<
	T extends {
		SPIN: string;
		canSupply: boolean;
		contractCapacity: ContractCapacityOption;
		quote: { code: string };
		requote: { code: string };
	},
>(
	fieldProps: DomainFieldProps<T> & {
		onSupplyPointVerified: (spin: string) => void;
	}
): ReactElement {
	const {
		disabled,
		setFieldValue,
		setFieldTouched,
		onSupplyPointVerified,
		values,
		name = 'SPIN',
	} = fieldProps;
	const [state, setState] = useState<
		| { is: 'idle' }
		| { is: 'loading' }
		| {
				content: 'multipleSpins' | 'offSupply';
				errorMessage: string;
				is: 'dialog';
		  }
		| { errorMessage: string; is: 'error' }
		| { is: 'success' }
	>({ is: 'idle' });

	const [verifiedSupplyPoints, setVerifiedSupplyPoints] = useSessionStorage<
		Record<string, QuotedElectricitySupplyPoint>
	>('verified-supply-points', {});
	const onExitMultiSpinDialog = () => {
		setState({
			is: 'error',
			errorMessage: t('obj:errors.multiple-spin'),
		});
	};

	const onExitOffSupplyDialog = () => {
		setState({
			is: 'error',
			errorMessage: t('obj:errors.off-supply-spin'),
		});
	};

	const dialogContents = {
		// Note: use a spin ending in 222222 to test this use case
		// see: https://github.com/octoenergy/occtomock/blob/main/occtomock/mocks/response/handler.py#L162
		multipleSpins: {
			buttonText: `←${copy.inputAgain}`,
			onClose: onExitMultiSpinDialog,
			content: <p>{copy.multiSpinDetected}</p>,
			title: copy.sorry,
		},
		offSupply: {
			buttonText: '戻る',
			onClose: onExitOffSupplyDialog,
			content: (
				<>
					<p>
						現在のご契約に何かしらの問題があるようです。ご契約中の電力会社に、詳細をお問い合わせください。
					</p>
					<p>問題が解決次第、オクトパスへのお申し込みが可能になります。</p>
				</>
			),
			title: 'お申し込みが完了できません',
		},
	};

	const offSupplyReasons = [
		CanSupplyRejectionType.OnContractWithOutstandingMoveOut,
		CanSupplyRejectionType.InvalidDecommissioningStatus,
	];

	useEffect(() => {
		const touchSPINField = async () => {
			switch (state.is) {
				case 'dialog':
				case 'error':
					setFieldTouched('SPIN', true);
			}
		};
		touchSPINField();
	}, [state.is]);

	useEffect(() => {
		let isCancelled = false;
		const getAndSetSupplyPoint = async () => {
			try {
				setState({ is: 'loading' });
				const isGridOperatorSupported = SUPPORTED_GRID_OPERATOR_CODES.some(
					(supportedGridOperatorCode) =>
						supportedGridOperatorCode === gridOperatorCodeFromSPIN(values.SPIN)
				);

				if (!isGridOperatorSupported) {
					return setState({
						is: 'error',
						errorMessage: t('obj:errors.spin-area-not-supported'),
					});
				}

				if (values.SPIN[3] !== '0') {
					return setState({
						is: 'error',
						errorMessage: t('obj:errors.high-voltage-spin-not-supported'),
					});
				}

				const { fetchElectricitySupplyPointDetails } =
					await apiClient.post<FetchElectricitySupplyPointDetailsMutation>(
						'/api/onboarding/fetch-spin',
						{
							input: {
								spin: values.SPIN.replace(/-/g, ''),
								quoteRequestCode: values.requote?.code || values.quote.code,
							},
						}
					);

				// @todo: update the apiClient to use an AbortController to also cancel the fetch request
				// see: https://dev.to/pallymore/clean-up-async-requests-in-useeffect-hooks-90h#:~:text=But%20You%20Really%20Should%20Do%20This
				if (isCancelled) {
					return;
				}

				const [quotedSupplyPoint] =
					fetchElectricitySupplyPointDetails?.quoteRequest
						?.quotedSupplyPoints || [];

				if (
					quotedSupplyPoint?.__typename !== 'QuotedElectricitySupplyPoint' ||
					!quotedSupplyPoint
				) {
					Sentry.captureMessage(
						'No supply points were returned from the fetchElectricitySupplyPointDetails mutation. This could be because of an invalid SPIN, or an internal Kraken error.'
					);
					/** This is most likely an invalid SPIN. */
					return setState({
						is: 'error',
						errorMessage: t('obj:errors.invalid-spin'),
					});
				}

				if (!quotedSupplyPoint.canSupply) {
					if (
						offSupplyReasons.some((reason) =>
							quotedSupplyPoint.rejectionReasons.includes(reason)
						)
					) {
						sendValidateSPINAnalytics({
							rejection_reason: 'off-supply-spin',
						});
						return setState({
							is: 'dialog',
							content: 'offSupply',
							errorMessage: t('obj:errors.off-supply-spin'),
						});
					}

					return setState({
						is: 'error',
						errorMessage: t('obj:errors.can-not-supply-spin'),
					});
				}

				/** Supply point exists and can be supplied */
				setFieldValue(`quotedSupplyPoint`, quotedSupplyPoint);

				setState({ is: 'success' });
				onSupplyPointVerified(
					/**@todo Make non-nullable in Kraken API */
					quotedSupplyPoint.supplyPointDetails?.spin as string
				);
				setVerifiedSupplyPoints((prev) => ({
					...prev,
					[values.quote.code + values.SPIN]:
						quotedSupplyPoint as QuotedElectricitySupplyPoint,
				}));
				if (quotedSupplyPoint.hasMultipleSpins) {
					sendValidateSPINAnalytics({
						rejection_reason: 'has-multiple-spins',
					});
					setFieldValue(`quotedSupplyPoint`, quotedSupplyPoint);
					return setState({
						is: 'dialog',
						content: 'multipleSpins',
						errorMessage: '',
					});
				}
			} catch (error) {
				/** Allow user to continue OBJ regardless of OCCTO or Kraken service errors */
				if (isServiceError(error)) {
					Sentry.captureMessage(
						'There was a service error in the fetchElectricitySupplyPointDetails mutation.',
						{ extra: { error } }
					);
					setFieldValue(`quotedSupplyPoint`, {
						canSupply: true,
						supplyPointDetails: { spin: values.SPIN.replace(/-/g, '') },
					});
					setState({ is: 'success' });
					onSupplyPointVerified(values.SPIN);
				} else {
					/** Non-service errors indicate Octopus can not service this SPIN, i.e. SPIN invalid/already registered. */
					setState({
						is: 'error',
						errorMessage: t('obj:errors.invalid-spin'),
					});
				}
			}
		};

		if (values.SPIN.length < SPIN_CHARACTER_LENGTH) {
			setState({ ...state, is: 'idle' });
		}

		if (values.SPIN.length === SPIN_CHARACTER_LENGTH) {
			const verifiedSupplyPoint =
				verifiedSupplyPoints[values.quote.code + values.SPIN];
			if (verifiedSupplyPoint) {
				setFieldValue(`quotedSupplyPoint`, verifiedSupplyPoint);
				setState({ is: 'success' });
				onSupplyPointVerified(values.SPIN);
			} else {
				getAndSetSupplyPoint();
			}
		}
		return () => {
			isCancelled = true;
		};
	}, [values.SPIN]);

	const onChange = (value: string) => {
		const dashedSPIN = numberHyphenator9000(
			value.replaceAll(/\s/g, ''),
			[2, 4, 4, 4, 4, 4],
			'-' // 03-0000-0000-0000-0000-0000
		);
		setFieldValue(name ?? '', dashedSPIN);
	};

	const { t } = useTranslation();

	return (
		<>
			<FormikTextField
				disabled={disabled}
				inputProps={{
					maxLength: SPIN_CHARACTER_LENGTH,
					inputMode: 'numeric',
					pattern: 'd*',
					onCompositionEndCapture: (e: CompositionEvent) =>
						onChange(fullWidthToHalfWidth(e.target.value, e.target.maxLength)),
				}}
				label={t('obj:inputs.spin')}
				name={name}
				onChange={(e: { target: HTMLInputElement }) => {
					onChange(e.target.value);
				}}
				validate={async () => {
					switch (state.is) {
						case 'dialog':
						case 'error':
							return state.errorMessage;
						case 'loading':
						case 'idle':
						case 'success':
							return '';
					}
				}}
				endIcon={
					state.is === 'success' ? (
						<IconSuccess size={20} title="success" />
					) : state.is === 'loading' ? (
						<Spinner width="20" height="20" />
					) : null
				}
			/>
			<Dialog
				ariaLabelledBy="SPINField-dialog-title"
				onClose={
					state.is === 'dialog' ? dialogContents[state.content].onClose : noop
				}
				open={state.is === 'dialog'}
			>
				<h3 id="SPINField-dialog-title" className="text-2xl font-bold">
					{state.is === 'dialog' && dialogContents[state.content].title}
				</h3>
				<div className="mt-6 mb-12 space-y-6">
					{state.is === 'dialog' && dialogContents[state.content].content}
				</div>
				<Button
					color="secondary"
					fullWidth
					onClick={
						state.is === 'dialog' ? dialogContents[state.content].onClose : noop
					}
				>
					{state.is === 'dialog' && dialogContents[state.content].buttonText}
				</Button>
			</Dialog>
		</>
	);
}
