import { createContext, useContext } from 'react';

import { noop } from '@/utils/noop';

type PostalAreasContextValue = {
	isPostalAreasFetching?: boolean;
	setIsPostalAreasFetching: (value: boolean) => void;
};

export const PostalAreasContext = createContext<PostalAreasContextValue>({
	isPostalAreasFetching: undefined,
	setIsPostalAreasFetching: noop,
});

export const usePostalAreasContext = (): PostalAreasContextValue =>
	useContext(PostalAreasContext);
