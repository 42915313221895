import { Button } from '@krakentech/coral';
import { FormikTextField } from '@krakentech/coral-formik';
import { TFunction, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { usePostalAreasContext } from '@/components/contexts/postalAreasContext';
import { validationRegex } from '@/components/helpers/validationRegex';
import { copy } from '@/copy';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import graphqlClient from '@/services/graphql-client';
import { PostalAreaFragment } from '@/services/typed-graphql-sdk';
import { CompositionEvent } from '@/types/inputs';
import { JAPANESE_POSTCODE_CHARACTER_LENGTH } from '@/utils/constants/constants';
import {
	PrefectureName,
	SUPPORTED_PREFECTURE_NAMES,
} from '@/utils/constants/industry/gridOperator';
import { fullWidthToHalfWidth } from '@/utils/formatters/fullWidthToHalfWidth';
import { numberHyphenator9000 } from '@/utils/formatters/numberHyphenator9000';
import { removeInvalidPostalAreas } from '@/utils/formatters/removeInvalidPostalAreas';
import { sendQuoteTariffsAnalytics } from '@/utils/googleAnalytics';

export const getPostcodeFieldValidation = (
	t: TFunction
): Yup.StringSchema<string | undefined, object> =>
	Yup.string()
		.trim()
		.required(t('errors.required'))
		.min(JAPANESE_POSTCODE_CHARACTER_LENGTH, t('errors.invalid-postcode'))
		.matches(validationRegex.postcode, t('errors.invalid-postcode'));

export function PostcodeField<
	T extends {
		billingPostalArea: PostalAreaFragment;
		billingPostcode: string;
		isBillingAddressSameAsAddress?: boolean;
		postalArea: PostalAreaFragment;
		postcode: string;
	},
>(
	fieldProps: DomainFieldProps<T> & {
		billing?: boolean;
		label?: string;
		loading?: boolean;
		loadingLabel?: ReactNode | string;
		onPostalArea: (postalArea: PostalAreaFragment) => void;
		variant?: 'default' | 'button';
	}
): ReactElement {
	const {
		values,
		setFieldValue,
		onPostalArea,
		billing,
		label = 'postcode',
		variant = 'default',
		loading,
		loadingLabel,
		disabled = false,
	} = fieldProps;

	const { t } = useTranslation();

	const { setIsPostalAreasFetching } = usePostalAreasContext();

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const urlPostcode = params.get('postcode') as string;
		if (urlPostcode && !billing) {
			setFieldValue('postcode', urlPostcode);
		}
	}, []);

	const [state, setState] = useState<
		| { is: 'idle' }
		| { is: 'postalAreaLoaded'; postalArea: PostalAreaFragment }
		| { errorMessage: string; is: 'error' }
	>({ is: 'idle' });

	const router = useRouter();
	const [, setPostalAreasToLS] = useLocalStorage<PostalAreaFragment[]>(
		'postalAreas',
		[
			{
				area: '',
				prefecture: '',
				city: '',
				postcode: '',
			},
		]
	);

	const [, setBillingPostalAreasToLS] = useLocalStorage<PostalAreaFragment[]>(
		'billingPostalAreas',
		[
			{
				area: '',
				prefecture: '',
				city: '',
				postcode: '',
			},
		]
	);

	const getAndSetPostalArea = async (postcode: string) => {
		try {
			setIsPostalAreasFetching?.(true);
			const { postalAreas } = await graphqlClient.getPostalAreas({
				postcode,
			});

			const [postalArea] = postalAreas;

			if (!postalArea) {
				throw new Error(copy.weCouldNotFindYourPostalArea);
			}

			removeInvalidPostalAreas(postalArea);

			if (billing) {
				setBillingPostalAreasToLS(postalAreas as PostalAreaFragment[]);
			}

			if (
				!SUPPORTED_PREFECTURE_NAMES.includes(
					postalArea.prefecture as PrefectureName
				)
			) {
				throw new Error(copy.weDoNotSupportYourPostalArea);
			}
			/** Postal area retrieved successfully. */

			onPostalArea(postalArea);
			setState({ is: 'postalAreaLoaded', postalArea });

			if (!billing) {
				setPostalAreasToLS(postalAreas as PostalAreaFragment[]);
				if (router.asPath === '/tariffs') {
					setFieldValue('prefecture', postalArea.prefecture);
					sendQuoteTariffsAnalytics();
				}
			}
		} catch (error) {
			const errorMessage = JSON.stringify(error).match(/invalid/gi)
				? t('common:errors.invalid-postcode')
				: copy.errorProcessingPostcode;

			return setState({
				is: 'error',
				errorMessage,
			});
		} finally {
			setIsPostalAreasFetching?.(false);
		}
	};

	const onChange = (input: string) => {
		const dashedPostcode = numberHyphenator9000(
			input,
			[3, 4],
			'-' // 151-0071
		);
		setFieldValue(billing ? 'billingPostcode' : 'postcode', dashedPostcode);
		if (
			dashedPostcode.length === JAPANESE_POSTCODE_CHARACTER_LENGTH &&
			variant !== 'button'
		) {
			getAndSetPostalArea(dashedPostcode);
		}
	};

	const onSubmitButtonVariant = (input: string) => {
		const dashedPostcode = numberHyphenator9000(
			input,
			[3, 4],
			'-' // 151-0071
		);

		if (dashedPostcode.length === JAPANESE_POSTCODE_CHARACTER_LENGTH) {
			getAndSetPostalArea(dashedPostcode);
		}
	};

	return (
		<div className="flex flex-col space-y-4">
			<FormikTextField
				inputProps={{
					maxLength: JAPANESE_POSTCODE_CHARACTER_LENGTH,
					inputMode: 'numeric',
					onCompositionEndCapture: (e: CompositionEvent) =>
						onChange(fullWidthToHalfWidth(e.target.value, e.target.maxLength)),
				}}
				attributes={{ 'data-testid': 'postcode-input' }}
				type="search"
				label={label}
				name={billing ? 'billingPostcode' : 'postcode'}
				onChange={(e: { target: HTMLInputElement }) => onChange(e.target.value)}
				validate={async () => (state.is === 'error' ? state.errorMessage : '')}
				disabled={disabled}
			/>
			{variant === 'button' && (
				<Button
					fullWidth
					color="secondary"
					onClick={() => {
						onSubmitButtonVariant(values.postcode);
					}}
					loading={loading ? true : undefined}
					loadingLabel={loadingLabel}
				>
					{copy.seeAllPlans}
				</Button>
			)}
		</div>
	);
}
