import { PropertyType } from '@/components/forms/fields/AddressFields';
import {
	COSGainType,
	MarketingCommunicationsOpt,
} from '@/components/pages/join/validationSchema';
import bankCodes from '@/data/bankCodes.json';
import {
	BankAccountTypeChoices,
	BuildingType,
	PaymentTypeChoices,
	QuotedElectricityProduct,
	QuotedElectricitySupplyPoint,
} from '@/services/typed-graphql-sdk';
import { QuoteWithNonNullableQuotedSupplyPoints } from '@/types/join';
import {
	CURRENT_MONTH_NUMBER,
	DEFAULT_AMPERAGE,
	KVA_VALUES,
} from '@/utils/constants/constants';
import {
	GRID_OPERATOR_CODE_BY_GRID_OPERATOR_NAME,
	GridOperatorCode,
	GridOperatorTier,
} from '@/utils/constants/industry/gridOperator';
import {
	ContractCapacityOption,
	DEFAULT_USAGE_TIER,
} from '@/utils/constants/industry/industry';
import { hasAccumulator } from '@/utils/constants/marketing';

export const joinFormInitialStepValuesFIT = {
	estimation: {
		hasAccumulator: 'does-not-have-accumulator' as hasAccumulator, // @todo should be able to accept boolean
		electricityGeneratedLastYear: '',
	},
	yourDetails: {
		currentGenerationContractNumber: '',
		currentGenerationPurchasor: '',
		GPIN: '',
		fileAttachmentId: '',
	},
};

export const joinFormInitialStepValues = {
	estimation: {
		usageTier: DEFAULT_USAGE_TIER,
		buildingType: BuildingType.Apartment,
		numberOfPeople: 1,
		contractCapacity: '' as ContractCapacityOption,
		amperage: DEFAULT_AMPERAGE,
		kva: KVA_VALUES[0],
		usageAmount: '',
		/** @todo Does the quote belong in the estimation step? */
		quote: {
			code: '',
			quotedSupplyPoints: [],
		} as QuoteWithNonNullableQuotedSupplyPoints,
		postcode: '',
		lastUsageMonth: CURRENT_MONTH_NUMBER,
		hasValidJourneyForPostcode: true,
		...joinFormInitialStepValuesFIT.estimation,
	},
	quoteGeneration: {},
	product: {
		quotedElectricityProduct: {
			product: { code: '' },
		} as QuotedElectricityProduct,
		quotedGenerationProduct: {} as QuotedElectricityProduct,
		productCode: '' /* shortcut only */,
	},
	yourDetails: {
		COSGainType: COSGainType.switchIn,
		moveInDate: '',
		currentRetailer: '',
		currentSupplierContractNumber: '',
		SPIN: '',
		gridOperatorCodeFromPostalArea:
			GRID_OPERATOR_CODE_BY_GRID_OPERATOR_NAME.TEPCO,
		gridOperatorTier: '' as GridOperatorTier,
		gridOperatorCodeFromSPIN: '' as GridOperatorCode,
		canSupply: false,
		quotedSupplyPoint: {} as QuotedElectricitySupplyPoint,
		requote: {
			code: '',
			quotedSupplyPoints: [],
		} as QuoteWithNonNullableQuotedSupplyPoints,
		requotedElectricityProduct: {
			product: { code: '' },
		} as QuotedElectricityProduct,
		postcode: '',
		addressLine1: '',
		postalArea: { area: '', city: '', prefecture: '', postcode: '' },
		isBillingAddressSameAsAddress: true,
		billingAddressLine1: '',
		billingPostcode: '',
		billingBuildingName: '',
		billingRoomNumber: '',
		billingBuildingNumber: '',
		buildingName: '',
		buildingNumber: '',
		roomNumber: '',
		propertyType: 'apartment' as PropertyType,
		billingPropertyType: 'apartment' as PropertyType,
		billingPostalArea: { area: '', city: '', prefecture: '', postcode: '' },
		lastName: '',
		firstName: '',
		lastNameKatakana: '',
		firstNameKatakana: '',
		contractFamilyName: '',
		contractGivenName: '',
		contractFamilyNameKana: '',
		contractGivenNameKana: '',
		email: '',
		mobile: '',
		marketingCommunicationsOpt: MarketingCommunicationsOpt.in,
		isContractNameSameAsCustomerName: true,
		saveProgress: false,
		...joinFormInitialStepValuesFIT.yourDetails,
	},
	payment: {
		cardNumber: '',
		cardExpiryDate: '',
		cardCVC: '',
		creditCardToken: '',
		bankAccountHolderName: '',
		bankAccountNumber: '',
		bankAccountBankCode:
			bankCodes.find((bankCode) => bankCode.name === 'みずほ銀行')?.code || '',
		bankAccountBranchCode: '',
		bankAccountType: BankAccountTypeChoices.Ordinary,
		bankAccountToken: '',
		paymentType: '' as PaymentTypeChoices,
		agreeToKonbiniPayment: false,
	},
	final: {
		agreeToTermsAndConditions: false,
		accountNumber: '',
		token: '',
		userId: '',
		hasUsablePassword: false,
	},
	moveOutAndMoveIn: {
		moveOutDate: '',
		moveOutPropertyId: '',
		moveOutAccountId: '',
	},
	rewardCodeParams: {
		affiliateLinkSubdomain: '',
		referralCode: '',
		referralSchemeCode: '',
	},
};

export const joinFormInitialValues = {
	...joinFormInitialStepValues.estimation,
	...joinFormInitialStepValues.quoteGeneration,
	...joinFormInitialStepValues.product,
	...joinFormInitialStepValues.yourDetails,
	...joinFormInitialStepValues.payment,
	...joinFormInitialStepValues.final,
	...joinFormInitialStepValues.moveOutAndMoveIn,
	...joinFormInitialStepValues.rewardCodeParams,
};

export type JoinFormValues = typeof joinFormInitialValues;
