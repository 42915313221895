/* eslint sort-keys:error */

export const featuresConfig = {
	NEXT_PUBLIC_CMS_FRIEND_PAGE:
		process.env.NEXT_PUBLIC_CMS_FRIEND_PAGE === 'true',
	NEXT_PUBLIC_EMAIL_VALIDATION_SERVICE_DISABLED:
		process.env.NEXT_PUBLIC_EMAIL_VALIDATION_SERVICE_DISABLED === 'true',
	NEXT_PUBLIC_FEATURE_ENABLE_ALL_ELECTRIC_FOR_REGULAR_OBJ:
		process.env.NEXT_PUBLIC_FEATURE_ENABLE_ALL_ELECTRIC_FOR_REGULAR_OBJ ===
		'true',
	NEXT_PUBLIC_FEATURE_LOVE_AND_POWER:
		process.env.NEXT_PUBLIC_FEATURE_LOVE_AND_POWER === 'true',
	NEXT_PUBLIC_FEATURE_OBJ_MOVE:
		process.env.NEXT_PUBLIC_FEATURE_OBJ_MOVE === 'true',
	NEXT_PUBLIC_FEATURE_SAVE_ONBOARDING_JOURNEY_PROGRESS:
		process.env.NEXT_PUBLIC_FEATURE_SAVE_ONBOARDING_JOURNEY_PROGRESS === 'true',
	NEXT_PUBLIC_NAVIGATOR_REGIONAL_CAMPAIGN:
		process.env.NEXT_PUBLIC_NAVIGATOR_REGIONAL_CAMPAIGN === 'true',
	NEXT_PUBLIC_NEW_WALLET: process.env.NEXT_PUBLIC_NEW_WALLET === 'true',
	NEXT_PUBLIC_PV_SERVICE: process.env.NEXT_PUBLIC_PV_SERVICE === 'true',
	NEXT_PUBLIC_QUICK_QUOTE_OBJ:
		process.env.NEXT_PUBLIC_QUICK_QUOTE_OBJ === 'true',
	NEXT_PUBLIC_SHOW_DR_TIPS_LIST:
		process.env.NEXT_PUBLIC_SHOW_DR_TIPS_LIST === 'true',
	NEXT_PUBLIC_WEST_JAPAN_CAMPAIGN:
		process.env.NEXT_PUBLIC_WEST_JAPAN_CAMPAIGN === 'true',
};
